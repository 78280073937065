.massage {
    background-color: #fff6e8;
    color: #000000;
  }
  

  .massage .first {
    background-image: url(../dist/pp3.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 500px;
  }
  
  .massage h1 {
    margin-top: 0.5em;
    font-size: 5vw;
    text-align: center;
  }
  
  .massage h2 {
    text-align: center;
    font-size: 2vw;
  }
  
  .massage .row {
    font-family: 'Cantata One', serif;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  
  @media only screen and (max-width: 600px) {
    .massage {
      height: 100vh;
      overflow: hidden;  }
  
    .massage h1 {
      margin-top: 10vw;
      font-size: 15vw;
    }

    .massage h2 {
      font-size: 4vw;
    }

    .massage h3 {
      font-size: 5vw;
    }
  
    .massage .first {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-attachment: scroll;
      height: 50%;
      margin-top: 5vw;
    }
  }