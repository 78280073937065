.menu {
    position: fixed;
    top: 4rem;
    right: 1rem;
    /* text-align: right; */
    z-index: 99;
    font-size: 2vw;
    font-family: 'Cantata One', serif;
}
.menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.menu li {
    margin-left: 0.4rem;
}

.menu a {
    text-decoration: none;
    padding-right: 0.5em;
    padding-left: 0.5em;
    border-radius: 0px;
    opacity: 1;
    color:#394E85;
    /* background-color: #FFF6E8; */
    font-weight: 600;
    text-decoration: none;
    text-shadow: var(--fake-text-border);
}

.menu li a:hover {
    color: #FFF6E8;
}
.menu li a.is-active {
    color: #FFF6E8;
    /* text-shadow: var(--fake-text-border); */
}

.current {
    position: relative;
    right: 0.2em;
    top: 0.5em;
}

img {
    width: 50%;
    height: auto;
}

.current a:hover {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
}