.contact {
    display: grid;
    place-content: right;
    /* padding: 5rem; */
background-image:url(../dist/h\ copy.png);
background-position:top; 
background-repeat: no-repeat;
background-size: cover;

}

.contact h1 {
    font-size: 2vw;
    text-align: center;
    margin-top: -5.5em;
}


    @media only screen and (max-width: 600px) {
        .contact h1 {
            font-size: 5vw;
    }
    }