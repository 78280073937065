.hero h1 {
  margin-left: 2vw;
}
  
  .hero h3 {
    color: #394e85;
    font-weight: 1000;
    margin-top: -5vw;
    margin-left: 2vw;
    margin-bottom: 5vw;
    letter-spacing: 4px;
  }
  
  .hero .titles h2 {
    margin-top: -0.5em;
    letter-spacing: 3px;
    margin-left: 2vw;
    margin-bottom: 5vw;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
  
  .hero a {
    text-decoration: none;
    color: #394E85;
  }
  
  .hero a:hover {
    color: #FFF6E8;
  }

  .hero .second {
    background-image: url(../dist/pp4.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    height: 400px;
  }

  .hero .first {
    background-image: url(../dist/h\ copy\ 2.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 400px;
  }


  /* RESPONSIVE */

  @media only screen and (max-width: 600px) {
    .hero {
      height: 100vh;
      overflow: hidden;
      margin-bottom : -45vw;
    }
  
    .hero .first {
      height: 50%;
    }

    .hero .titles {
      margin-bottom: 10vw;
    }
  
    .hero .second {
      background-image: url(../dist/pp4.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-attachment: scroll ;
      height: 30%;
      margin-top: 5vw;
    }
  
    .hero .expanded {
      margin-top: -30vw;
      margin-left: 2vw;
      font-size: 50px;
      letter-spacing: 2px;
    }

  
    .hero .titles h2 {
      margin-left: 2vw;
      font-size: 4vw;
      letter-spacing: 1vw;
    }
  }
  