#footer {
    font-size: 3vw;
    color: #FFF6E8;
    text-align: center;
    background-color: #394E85;
    /* text-shadow: var(--fake-text-border);*/
    background-size: 100%;
    font-family: 'Cantata One', serif;
    font-weight: bolder;
    text-shadow: var(--fake-text-border);
} 

html {
    background-color: #394E85;

}
