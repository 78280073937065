

:root {
    scroll-behavior: smooth;
    --shadow-base: 0px 1px 2px rgba(0, 0, 0, 0.06),
        0px 1px 3px rgba(0, 0, 0, 0.1);
    --shadow-md: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
    --shadow-lg: 0px 10px 15px rgba(0, 0, 0, 0.1),
        0px 4px 6px rgba(0, 0, 0, 0.05);
    --border-color: rgba(0, 0, 0, 0.06);
    --fake-text-border: 1px 1px 1px var(--border-color),
        0 -1px 1px var(--border-color), 1px 1px 1px var(--border-color),
        -1px 0 0 var(--border-color), 1px 1px 0 var(--border-color),
        1px 0 0 var(--border-color), 0 -1px 0 var(--border-color);
}

body {
    background-color: #fff6e8;
    margin: 0;
    font-family: 'Cantata One', serif;
    cursor: url(https://cur.cursors-4u.net/symbols/sym-1/sym53.ani), url(https://cur.cursors-4u.net/symbols/sym-1/sym53.png), auto !important;} 

.App {
    overflow: hidden;
}

::selection {
    background-color:#394E85;
}


.hero {
    background-image: url(/src/dist/h.png);
    min-height: 100vh;
    /* padding: 2rem; */
    box-sizing: border-box;  
}

.projects,
.about-me,
.contact,
.massage {
    min-height: 100vh;
    /* padding: 2rem; */
    box-sizing: border-box;
}


h1 {
    font-size: 7vw;
    font-stretch: ultra-expanded;
    line-height: 1;
    font-weight: 700;
    color:#394E85;
    font-family: 'Cantata One', serif;
}

h2 {
    text-align: left;
    font-weight: lighter;
    font-family: 'Sora', sans-serif;
    color :#394E85;
    font-size: 5vw;
    /* text-shadow: var(--fake-text-border); */
}

h3 {
    text-align: left;
    font-weight: lighter;
    color: rgb(27, 27, 27);
    font-size: 3vw;
    margin-top: -0.1em;
    color: rgb(27, 27, 27);
    /* text-shadow: var(--fake-text-border); */
}

.column h3 {
    color: #394E85;
    font-weight: 600;
    text-decoration: none;
    /* text-shadow: var(--fake-text-border); */
}

.column h3:hover {
    color: #FFF6E8;
    text-shadow: none;
    text-shadow: var(--fake-text-border);
    cursor: pointer; 
    /* text-shadow: var(--fake-text-border); */
}


h4 {
    text-align: center;
    font-size: 10vw;
    /* line-height: 0.8;
    font-weight: 700; */
    color: #394E85;
}

h5 {
    padding: 1vh;
    font-size: 5vw;
    text-align: left;
}

/* Utilities */

.smallest {
    font-size: 0.6em;
}
.smaller {
    font-size: 0.75em;
}
.small {
    font-size: 0.9em;
}
.big {
    font-size: 1.25em;
}
.bigger {
    font-size: 1.65em;
}
.biggest {
    font-size: 1.8em;
}

.yellow {
    color: #394E85;
}

.text-border {
    text-shadow: var(--fake-text-border);
}


.expanded {
    font-stretch: 200%;
  }
